<div class="centered">
  <h1 class="huge uppercased">Spendi il tuo bonus cultura su 128Academy!</h1>
  <h1 class="uppercased">Convertilo usando il form sottostante</h1>
</div>

<div class="center-wrapper">
  <div class="centered-container">
    <app-redeem-coupon-form></app-redeem-coupon-form>
  </div>
</div>

<div class="center-wrapper padding-top-1rem">
  <div class="centered-container ">
    <h1 class="huge uppercased">FAQ</h1>
    <app-faq-list></app-faq-list>
  </div>
</div>



