<footer class="docs-footer">
  <div class="docs-footer-list">
    <div class="docs-footer-logo">
      <div class="footer-logo">
  <!--     <img class="docs-footer-angular-logo"
            src="https://i.ibb.co/R7kdcs8/sticker-generico.png"
            alt="Logo icon"> -->
<!--        <span><a href="example.com">Example</a></span>-->
        <span>128 Academy | info@128academy.com</span>
      </div>
    </div>

<!--    <div class="docs-footer-version">-->
<!--      <img class="angular-material-logo" src="logo2.svg"-->
<!--           alt="Angular Material icon">-->
<!--      <span class="version">Current Version: 1</span>-->
<!--    </div>-->

    <div class="docs-footer-copyright">
      <div>
        <span>Federico Doria | P.I. 04709400271</span>
        <span>Copyright© 2022 - Today</span>
      </div>
    </div>
  </div>
</footer>
