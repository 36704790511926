export class CouponForm {
  name: string;
  surname: string;
  email: string;
  course: number;
  coupon: string;
  agree: boolean;


  constructor(name: string, surname: string, email: string, course: number, coupon: string, agree: boolean) {
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.course = course;
    this.coupon = coupon;
    this.agree = agree;
  }
}
