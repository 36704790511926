import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-faq-element',
  templateUrl: './faq-element.component.html',
  styleUrls: ['./faq-element.component.css']
})
export class FaqElementComponent implements OnInit {

  @Input() title: string = "";
  @Input() description: string = "";

  constructor() {
  }

  ngOnInit(): void {
  }

}
