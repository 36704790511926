<mat-expansion-panel class="align-left">
  <mat-expansion-panel-header>
    <mat-panel-title class="bold">
      {{title}}
    </mat-panel-title>
    <mat-panel-description>
      {{description}}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
