import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {FormHttpService} from "../../services/form/form-http.service";
import {CouponForm} from "../../models/forms/CouponForm";
import {first} from "rxjs";
import {CourseHttpService} from "../../services/course/course-http.service";
import {CourseJSON} from "../../models/course/CourseJSON";
import {SnackbarNotificationService} from "../../services/notification/snackbar-notification.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmSubmissionDialogComponent} from "../confirm-submission-dialog/confirm-submission-dialog.component";


@Component({
  selector: 'app-redeem-coupon-form',
  templateUrl: './redeem-coupon-form.component.html',
  styleUrls: ['./redeem-coupon-form.component.css']
})
export class RedeemCouponFormComponent implements OnInit {

  nameFormControl = new FormControl('', [Validators.required]);
  surnameFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  courseFormControl = new FormControl('', [Validators.required]);
  couponFormControl = new FormControl('', [Validators.required]);
  agreeCheckFormControl = new FormControl('', [Validators.required]);
  courseList: CourseJSON[] = [];

  isSubmitting: boolean = false;

  constructor(private formHttpService: FormHttpService,
              private courseHttpService: CourseHttpService,
              private snackbarService: SnackbarNotificationService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.courseHttpService.getCourseList().pipe(first()).subscribe((lista) => {
      this.courseList = lista;
    });
  }


  submitData(name: string, surname: string, email: string, course: number, coupon: string, agree: boolean) {
    this.isSubmitting = true;
    //console.log(name + " " + surname + " " + email + " " + course + " " + coupon + " " + agree);

    // solo controllo del buono senza riscossione
    // this.formHttpService.checkVoucher(new CouponForm(name, surname, email, course, coupon)).pipe(first()).subscribe((response) => {

    // riscuoti buono
    this.formHttpService.redeemVoucher(new CouponForm(name, surname, email, course, coupon, agree)).pipe(first()).subscribe((response) => {
      if (response.error) {
        this.snackbarService.openSnackBarMessages(response.errormessages);
      } else {
        const dialogRef = this.dialog.open(ConfirmSubmissionDialogComponent, {
          data: {title: "Grazie!", confirmMessages: response.errormessages ?? []},
        });
        this.emptyFormFields();
      }
      this.isSubmitting = false;
    })
  }

  emptyFormFields() {
    this.nameFormControl.reset();
    this.surnameFormControl.reset();
    this.emailFormControl.reset();
    this.courseFormControl.reset();
    this.couponFormControl.reset();
  }
}
