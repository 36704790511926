import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NavbarComponent} from './components/general/navbar/navbar.component';
import {FooterComponent} from './components/general/footer/footer.component';
import {HomeComponent} from './components/general/home/home.component';
import {RedeemCouponFormComponent} from './components/redeem-coupon-form/redeem-coupon-form.component';
import {PageNotFoundComponent} from './components/general/page-not-found/page-not-found.component';

import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {FormHttpService} from "./services/form/form-http.service";
import {HttpClientModule} from "@angular/common/http";
import {MatSelectModule} from "@angular/material/select";
import {CourseHttpService} from "./services/course/course-http.service";
import {FaqListComponent} from './components/faq-list/faq-list.component';
import {MatTreeModule} from "@angular/material/tree";
import {MatIconModule} from "@angular/material/icon";
import {MatExpansionModule} from "@angular/material/expansion";
import {FaqElementComponent} from './components/faq-element/faq-element.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {
  ConfirmSubmissionDialogComponent
} from './components/confirm-submission-dialog/confirm-submission-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    RedeemCouponFormComponent,
    PageNotFoundComponent,
    FaqListComponent,
    FaqElementComponent,
    ConfirmSubmissionDialogComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatToolbarModule,
        MatButtonModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatTreeModule,
        MatIconModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatDialogModule,
        MatCheckboxModule,
        MatProgressSpinnerModule
    ],
  providers: [
    {provide: FormHttpService, useClass: FormHttpService},
    {provide: CourseHttpService, useClass: CourseHttpService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
