import {Injectable} from '@angular/core';
import {catchError, map, Observable} from "rxjs";
import {CourseJSON} from "../../models/course/CourseJSON";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {handleError, ResponseModel} from "../../models/response/ResponseModel";
import {SnackbarNotificationService} from "../notification/snackbar-notification.service";

@Injectable({
  providedIn: 'root'
})


export class CourseHttpService {

  constructor(private http: HttpClient, private snackbarService: SnackbarNotificationService) {
  }

  getCourseList(): Observable<CourseJSON[]> {
    return this.http.get<ResponseModel>(environment.api.BASE_API_URL + environment.api.COURSES_URL, {})
      .pipe(
        map<ResponseModel, CourseJSON[]>((data) => {
          let courses: CourseJSON[] = data.payload.map((course: any) => {
            let tempCourse: CourseJSON = Object.assign(new CourseJSON(), course);
            return tempCourse;
          });
          return courses;
        }),
        catchError((err) => handleError(err, this.snackbarService, ["Errore durante il recupero dei corsi disponibili.", "Ritenta ricaricando la pagina.", "Contattaci se l'errore persiste ❤"]))
      );
    ;
  }

}

