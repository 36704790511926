import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map, Observable, tap} from "rxjs";
import {CouponForm} from "../../models/forms/CouponForm";
import {SimpleResponse} from "../../models/response/SimpleResponse";
import {handleError, ResponseModel} from "../../models/response/ResponseModel";
import {matSnackBarAnimations} from "@angular/material/snack-bar";
import {SnackbarNotificationService} from "../notification/snackbar-notification.service";

@Injectable({
  providedIn: 'root'
})
export class FormHttpService {

  constructor(private http: HttpClient, private snackbarService: SnackbarNotificationService) {
  }

  checkVoucher(newCouponPayload: CouponForm): Observable<SimpleResponse> {
    const options = {
      headers: new HttpHeaders({
        'cache-control': 'no-cache'
      })
    };

    const formData = new FormData();
    formData.set("name", newCouponPayload.name);
    formData.set("surname", newCouponPayload.surname);
    formData.set("email", newCouponPayload.email);
    formData.set("course", newCouponPayload.course + "");
    formData.set("coupon", newCouponPayload.coupon);
    formData.set("agree", newCouponPayload.agree + "");

    return this.http.post<ResponseModel>(environment.api.BASE_API_URL + '/checkvoucher', formData, options).pipe(
      map<ResponseModel, SimpleResponse>((userResponse: ResponseModel) => {
        return {error: userResponse.error ?? false, errormessages: userResponse.errormessages ?? []};
      }),
      catchError((err) => handleError(err, this.snackbarService, ["Errore durante l'invio del form.", "Ritenta.", "Contattaci se l'errore persiste❤"]))
    );
  }

  redeemVoucher(newCouponPayload: CouponForm): Observable<SimpleResponse> {
    const options = {
      headers: new HttpHeaders({
        'cache-control': 'no-cache'
      })
    };

    const formData = new FormData();
    formData.set("name", newCouponPayload.name);
    formData.set("surname", newCouponPayload.surname);
    formData.set("email", newCouponPayload.email);
    formData.set("course", newCouponPayload.course + "");
    formData.set("coupon", newCouponPayload.coupon);
    formData.set("agree", newCouponPayload.agree + "");

    return this.http.post<ResponseModel>(environment.api.BASE_API_URL + '/redeemvoucher', formData, options).pipe(
      map<ResponseModel, SimpleResponse>((userResponse: ResponseModel) => {
        return {error: userResponse.error ?? false, errormessages: userResponse.errormessages ?? []};
      }),
      catchError((err) => handleError(err, this.snackbarService, ["Errore durante l'invio del form.", "Ritenta.", "Contattaci se l'errore persiste ❤"]))
    );
  }

}



