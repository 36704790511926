<mat-toolbar color="primary">
  <!-- <span>{{title}}</span> -->
  <a class="navbar-brand" href="/">
    <div class="logo-image">
          <a href="https://128academy.com/">
          <img src="https://i.ibb.co/9gx0Y1s/logo18app128ady.png" class="img-fluid" href="www.128academy.com" >
        </a>
    </div>
</a>
</mat-toolbar>
