// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: "128Academy",
  api: {
    BASE_API_URL: "https://18app2023-1-q1950313.deta.app",
    // COURSES_URL: "https://www.federicodoria.com/wp-content/uploads/2022/04/courses.json"
    // COURSES_URL: "https://jsonkeeper.com/b/4AWB"
    COURSES_URL: "/courses"//"https://api.npoint.io/81afde1608c501ac6215"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
