<mat-card class="wrap-content">
  <div class="input-form">
    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Nome</mat-label>
      <input type="text" [formControl]="nameFormControl" matInput placeholder="Nome" value="" #nameTxtInput>
      <mat-error *ngIf="nameFormControl.hasError('required')">
        Il nome è <strong>obbligatorio</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Cognome</mat-label>
      <input type="text" [formControl]="surnameFormControl" matInput placeholder="Cognome" value="" #surnameTxtInput>
      <mat-error *ngIf="surnameFormControl.hasError('required')">
        Il cognome è <strong>obbligatorio</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Email</mat-label>
      <input type="email" matInput [formControl]="emailFormControl" placeholder="mariorossi@example.com" #emailTxtInput>
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Inserisci un indirizzo email valido
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        L'email è <strong>obbligatoria</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Seleziona il corso che vuoi acquistare</mat-label>
      <mat-select #courseSelect [formControl]="courseFormControl">
        <mat-option *ngFor="let option of courseList" [value]="option.idCorso">{{ option.nomeCorso }}</mat-option>
      </mat-select>
      <mat-error *ngIf="courseFormControl.hasError('required')">
        La scelta del corso è <strong>obbligatoria</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Coupon Bonus Cultura</mat-label>
      <input type="text" matInput [formControl]="couponFormControl" placeholder="ABC0123" value="" #couponTxtInput>
      <mat-error *ngIf="couponFormControl.hasError('required')">
        Il coupon è <strong>obbligatorio</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox [formControl]="agreeCheckFormControl" #agreeCheckbox>Dichiaro di essere il titolare del buono e di
      aver letto e compreso quanto scritto nelle FAQ sottostanti. *
    </mat-checkbox>

    <div class="padding-top-1rem">
            <button mat-raised-button color="primary" *ngIf="!isSubmitting"
                    [disabled]=" !nameFormControl.valid || !surnameFormControl.valid || !emailFormControl.valid || !courseFormControl.valid || !couponFormControl.valid || !agreeCheckbox.checked"
                    (click)="submitData( nameTxtInput.value, surnameTxtInput.value, emailTxtInput.value, courseSelect.value,  couponTxtInput.value, agreeCheckbox.checked);">
              Invia
            </button>

      <div class="wrap-content mat-spinner-center" *ngIf="isSubmitting">
        <mat-spinner [diameter]="50" ></mat-spinner>
      </div>


    </div>

  </div>

</mat-card>
<br>
