<div class="faq-list-width">
  <mat-accordion>
    <app-faq-element title="Cos'è 18App?" description="">
      <p align="left">Il bonus cultura è un’iniziativa per promuovere la cultura tra i giovani. In particolare, <b>se sei del 2004, hai 500€ da poter spendere in diverse attività </b> come cinema, musica e molto altro,<b> compresi i corsi di 128 Academy. </b>
       <br>Se vuoi saperne di più sul bonus, puoi visitare il <a href="https://www.18app.italia.it/">sito ufficiale da qui.</a><br><br>
        <a href="https://www.18app.italia.it/"><img src="https://i.ibb.co/yBMMpYn/sticker-generico-copia.png" alt="sticker-generico-copia" width="100px" height="auto"></a>
      </p>

    </app-faq-element>
    <app-faq-element title="Come utilizzo il bonus su 128 Academy?">
      <p align="left">1. Scegli il corso che vuoi acquistare e verifica dal form qui sopra che sia disponibile all’acquisto con 18app.
<br>
        2. Genera un buono del valore esatto in base al corso che vuoi acquistare. ATTENZIONE, se il buono non ha lo stesso valore del corso, la convalida non andrà a buon fine.
<br>
        3. Compila il form qui sopra, inserendo i tuoi dati personali. Attenzione, il corso può essere riscattato soltanto da chi è proprietario del coupon, quindi verifica di scrivere correttamente i tuoi dati personali. Seleziona nel form il corso che vuoi acquistare, e infine inserisci il codice del coupon da te generato in precedenza
<br>
        4. Se la validazione va a buon fine, entro 48 ore dalla convalida riceverai via email un codice sconto del 100% per acquistare il corso normalmente. Inserendo il coupon inviato via email, il prezzo finale al carrello sarà ovviamente di 0 euro e non sarà necessario inserire metodi di pagamento. Questo passaggio però è fondamentale perché ti permette di creare il tuo account.
<br>
        5. Una volta registrato alla piattaforma e completato il checkout con il coupon, avrai accesso regolare alla piattaforma

      </p>
    </app-faq-element>
    <app-faq-element title="Come funzionano i rimborsi?">
      <p align="left">Se acquisti il corso con 18app <b>non</b> c’è la possibilità di ottenere rimborsi. Pertanto, <b>verifica bene tutte le informazioni in fase di acquisto</b> perché in caso di convalida per un corso sbagliato, <b>il bonus non sarà rimborsabile!</b>
      </p>
    </app-faq-element>
    <app-faq-element title="Posso acquistare più volte lo stesso corso?">
      <p align="left">Puoi acquistare una copia per ogni corso nella piattaforma usando 18app. Non è possibile acquistare più volte lo stesso corso.
      </p>
    </app-faq-element>
    <app-faq-element title="Quali corsi posso acquistare?">
      <p align="left">
        Nel form, sulla selezione del corso, trovi la lista dei corsi che sono compatibili per l’acquisto con 18App
      </p>
    </app-faq-element>
    <app-faq-element title="Posso utilizzare gli sconti della piattaforma?">
      <p align="left">
        No, l’acquisto dei nostri corsi tramite 18App non prevede l’utilizzo di possibili sconti o coupon che potrebbero essere disponibili per chi acquista con Carte/PayPal i corsi.
      </p>
    </app-faq-element>
    <app-faq-element title="Ho qualche problema, come risolvo?">
      <p align="left">
        Per qualsiasi domanda, o problema nell’utilizzo di 18app, puoi inviare una email a <a href="mailto:info@128academy.com">info@128academy.com</a>
      </p>
    </app-faq-element>
  </mat-accordion>
</div>

