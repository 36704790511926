export class CourseJSON {
  idCorso: number;
  nomeCorso: string;
  prezzoCorso: number;


  constructor(idCorso: number = -1, nomeCorso: string = "", prezzoCorso: number = 0) {
    this.idCorso = idCorso;
    this.nomeCorso = nomeCorso;
    this.prezzoCorso = prezzoCorso;
  }


}

