import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../environments/environment";
import {first} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SnackbarNotificationService {

  constructor(private snackBar: MatSnackBar) {
  }

  openSnackBarMessage(message: string, action: string = "✖") {
    return this.snackBar.open(message, action, {
      panelClass: "snackbar-style"
    }).onAction();
  }

  openSnackBarMessages(messages: string[], action: string = "✖") {
    let message = "";
    messages.forEach((msg, i) => {
      message += msg + "\n";
    })
    return this.snackBar.open(message, action, {
      panelClass: "snackbar-style"
    }).onAction();
  }

}
