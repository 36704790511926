import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  title: string;
  confirmMessages: string[];
}

@Component({
  selector: 'app-confirm-submission-dialog',
  templateUrl: './confirm-submission-dialog.component.html',
  styleUrls: ['./confirm-submission-dialog.component.css']
})
export class ConfirmSubmissionDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit(): void {
  }


}
