import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {SnackbarNotificationService} from "../../services/notification/snackbar-notification.service";

export interface ResponseModel {
  payload: any;
  status?: number;
  error?: boolean;
  errormessages?: string[];
}


export function handleError(error: HttpErrorResponse, snackbarService: SnackbarNotificationService | null = null, errorMsgs: string[] = ["Errore generico.", "Contattaci se l'errore persiste ❤"]) {
  console.log(error)
  if (snackbarService) {
    snackbarService.openSnackBarMessages(errorMsgs)
  }
  return throwError(() => new Error('Something bad happened; please try again later.'));
}
